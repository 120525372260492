import Grid from '@mui/material/Grid';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import HomeIcon from '@mui/icons-material/Home';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import React, { useEffect, useRef } from 'react';
import { emphasize, styled } from '@mui/material/styles';
import Chip from '@mui/material/Chip';
import { Skeleton, TextField, ToggleButton, ToggleButtonGroup, Tooltip } from '@mui/material';
import ViewListIcon from '@mui/icons-material/ViewList';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import Paper from '@mui/material/Paper';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Typography from '@mui/material/Typography';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import Button from '@mui/material/Button';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import TableBody from '@mui/material/TableBody';
import PropTypes from 'prop-types';
import ObjectUtil from '../../../utils/ObjectUtil';
import { Alert } from '@mui/lab';

const Title = styled(Paper)(({ theme }) => ({
  fontWeight: `bold`,
  padding: theme.spacing(1),
  textAlign: 'left',
}));

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'left',
}));

const CategoryViewPage = props => {
  const { history, category, cart, quantityChanged, width, cartItemUpdate, cartItemCreate, internalProduct, internalProductVariant, user, cartItemLoading } = props;

  const [view, setView] = React.useState('gallery');

  const [productFilter, setProductFilter] = React.useState([]);

  const handleChange = (event, nextView) => {
    if (nextView !== null) setView(nextView);
  };
  // console.log(productFilter);
  return !category?.fetching ? (
    <Grid item xs={12}>
      <Grid container>
        <Grid item xs={6}>
          <Typography align="left" variant="h5" style={{ fontWeight: 'bold' }}>
            {category.category_name}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <div style={{ float: `right`, marginBottom: 10 }}>
            <ToggleButtonGroup orientation="horizontal" value={view} exclusive onChange={handleChange} color={'primary'}>
              <Tooltip title="Table View" selected={view === 'table'}>
                <ToggleButton value="table" aria-label="table">
                  <ViewListIcon />
                </ToggleButton>
              </Tooltip>
              <Tooltip title="Gallery View">
                <ToggleButton value="gallery" aria-label="gallery" selected={view === 'gallery'}>
                  <ViewModuleIcon />
                </ToggleButton>
              </Tooltip>
            </ToggleButtonGroup>
          </div>
        </Grid>
      </Grid>

      {
        {
          gallery: (
            <Grid container spacing={1}>
              {category &&
                category?.products.map(product => (
                  <Grid key={`gallery-product-${product.id}`} item xs={6} md={4} lg={3}>
                    <Button
                      style={{ borderRadius: 0 }}
                      onClick={() => {
                        history.push(`/catalog/${category?.url}/product/${product?.id}`);
                        setTimeout(function() {
                          window.scrollTo(0, 0);
                        }, 30);
                      }}
                    >
                      <div className={`img`}>
                        <img
                          style={{ width: width < 600 ? 128 : 256, height: width < 600 ? 128 : 256 }}
                          src={window.S3_ENABLED ? `https://${window.AWS_BUCKET}.s3.amazonaws.com${product?.asset?.url}` : `${window.API_URL}/asset/${product?.asset?.id}/file`}
                          alt={product.product_name}
                        />
                      </div>
                    </Button>
                    <Title style={{ borderRadius: 0, textAlign: `center`, fontSize: width < 600 ? 12 : 16 }} sx={{ height: '5%' }}>
                      {product.product_name}
                    </Title>
                    <Item style={{ borderRadius: 0, textAlign: `center` }}>
                      {category?.price_override?.active === 1 && product?.product_variants.length >= 1 ? (
                        <span>
                          <span style={{ color: `red` }}>
                            <s>{product.unit_price}</s>
                          </span>
                          <span> ${product?.lowest_price?.lowest_total}</span>
                        </span>
                      ) : product?.customer_saved_pricing && product?.customer_saved_pricing?.item_total !== product.unit_price && product?.product_variants.length >= 1 ? (
                        <span>
                          <span style={{ color: `red` }}>
                            <s>{product.unit_price}</s>
                          </span>
                          <span> ${product?.customer_saved_pricing?.item_total}</span>
                        </span>
                      ) : (
                        <span>Unit Price: ${product.unit_price}</span>
                      )}
                    </Item>
                    <Item style={{ borderRadius: 0, textAlign: `center` }}>{product?.product_variants?.length} Types</Item>
                  </Grid>
                ))}
            </Grid>
          ),
          table: (
            <div>
              {category && category?.products && category?.products.length ? (
                category?.products
                  .filter(it => it?.product_variants?.length)
                  // .sort((a, b) => {
                  //   if (category?.id === window.SORT_CATEGORY) {
                  //     if (a.quantity === b.quantity) {
                  //       return 0;
                  //     }
                  //     if (a.quantity === 0) {
                  //       return 1;
                  //     }
                  //     if (b.quantity === 0) {
                  //       return -1;
                  //     }
                  //     return a.quantity - b.quantity;
                  //   } else {
                  //     return a.product_code - b.product_code;
                  //   }
                  // })
                  .map(product => (
                    <Grid container spacing={5} pt={5} key={`table-product-${product.id}`}>
                      <Grid item xs={12} md={2}>
                        <img
                          style={{ width: `100%`, width: 256, height: 256 }}
                          src={window.S3_ENABLED ? `https://${window.AWS_BUCKET}.s3.amazonaws.com${product?.asset?.url}` : `${window.API_URL}/asset/${product?.asset?.id}/file`}
                          alt={product.product_name}
                        />
                      </Grid>

                      <Grid item xs={12} md={10}>
                        <Grid container spacing={2}>
                          <Grid item xs={12}>
                            <TableContainer component={Paper}>
                              <Table size="small" style={{ overflowX: `hidden` }}>
                                <TableHead>
                                  <TableRow>
                                    <TableCell align="left" style={{ fontWeight: 'bold' }} colSpan={width < 600 ? 2 : product.multiple ? 5 : 4}>
                                      <Typography variant="h1" style={{ fontSize: 22, fontWeight: `bold` }}>
                                        {product.product_name}
                                      </Typography>
                                    </TableCell>
                                    <TableCell align="right" style={{ fontWeight: 'bold' }}>
                                      <Tooltip title="View Product Information">
                                        <Button
                                          size="small"
                                          color="primary"
                                          onClick={() => {
                                            history.push(`/catalog/${category?.url}/product/${product?.id}`);
                                            setTimeout(function() {
                                              window.scrollTo(0, 0);
                                            }, 30);
                                          }}
                                        >
                                          <ArrowForwardIosIcon />
                                        </Button>
                                      </Tooltip>
                                    </TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  <TableRow>
                                    <TableCell style={{ padding: 0 }} colSpan={6}>
                                      <TextField
                                        fullWidth
                                        size={'small'}
                                        variant="outlined"
                                        label={'Search'}
                                        onChange={event => {
                                          let value = event.target.value;
                                          // if (value === '') {
                                          //   console.log('EMPTY');
                                          //   let newArray = [...productFilter];
                                          //   // newArray.splice(product.id, 1);
                                          //   delete newArray[product?.id];
                                          //   setProductFilter(newArray);
                                          // } else {
                                          //
                                          // }
                                          setProductFilter(oldFields => ({
                                            ...oldFields,
                                            [product.id]: { ...oldFields[product.id], value },
                                          }));

                                          // console.log('FILTERING');
                                          // setProductFilter(event.target.value);
                                          // // setFilteredList(
                                          // //   customers?.data?.filter(item => {
                                          // //     return (item?.user?.company_name ? `(${item?.user?.company_name}) ${item?.user?.firstname} ${item?.user?.lastname}` : `${item?.user?.firstname} ${item?.user?.lastname}`).includes(
                                          // //       event.target.value,
                                          // //     );
                                          // //   }),
                                          // // );
                                        }}
                                      />
                                    </TableCell>
                                  </TableRow>
                                </TableBody>
                                <TableBody style={{ overflowX: `hidden` }}>
                                  <TableRow>
                                    <TableCell
                                      align="left"
                                      style={{
                                        fontWeight: `bold`,
                                        minWidth: 100,
                                        maxWidth: 150,
                                        width: '100%',
                                        padding: 15,
                                      }}
                                    >
                                      Code
                                    </TableCell>
                                    <TableCell
                                      align="left"
                                      style={{
                                        fontWeight: `bold`,
                                        minWidth: 150,
                                        maxWidth: 300,
                                        padding: 15,
                                        width: '100%',
                                        display: width < 600 ? 'none' : 'table-cell',
                                      }}
                                    >
                                      Type
                                    </TableCell>
                                    <TableCell align="center" style={{ fontWeight: `bold`, minWidth: 50, maxWidth: 50, padding: 15 }}>
                                      {width < 600 ? `Price` : `Unit $`}
                                    </TableCell>
                                    {/*<TableCell*/}
                                    {/*  align="left"*/}
                                    {/*  style={{*/}
                                    {/*    fontWeight: `bold`,*/}
                                    {/*    width: '100%',*/}
                                    {/*    maxWidth: 50,*/}
                                    {/*    padding: 15,*/}
                                    {/*    display: width < 600 ? 'none' : 'table-cell',*/}
                                    {/*  }}*/}
                                    {/*/>*/}
                                    {product?.multiple ? (
                                      <TableCell align="center" style={{ fontWeight: `bold`, width: '100%', minWidth: 50, maxWidth: 150, padding: 15, display: width < 600 ? 'none' : 'table-cell' }}>
                                        Multiple
                                      </TableCell>
                                    ) : (
                                      ''
                                    )}
                                    <TableCell align="center" style={{ fontWeight: `bold`, width: '100%', minWidth: 50, maxWidth: 150, padding: 15 }}>
                                      Qty
                                    </TableCell>

                                    <TableCell align="center" style={{ fontWeight: `bold`, width: '100%', maxWidth: 100, padding: 15, display: width < 600 ? 'none' : 'table-cell' }}>
                                      Stock
                                    </TableCell>
                                  </TableRow>
                                </TableBody>

                                <TableBody style={{ overflowX: `hidden` }}>
                                  {product &&
                                    product?.product_variants
                                      // .sort((a, b) => (a.quantity > b.quantity ? 1 : b.quantity > a.quantity ? -1 : 0))
                                      // .sort((a, b) => b.quantity - a.quantity)
                                      .sort((a, b) => {
                                        if (category?.id === window.SORT_CATEGORY) {
                                          if (a.quantity === b.quantity) {
                                            return 0;
                                          }
                                          if (a.quantity === 0) {
                                            return 1;
                                          }
                                          if (b.quantity === 0) {
                                            return -1;
                                          }
                                          return a.quantity - b.quantity;
                                        } else {
                                          return a.product_code - b.product_code;
                                        }
                                      })
                                      .filter(productVariant => {
                                        return (
                                          (productFilter && productFilter.length === 0) ||
                                          productFilter[product?.id] == null ||
                                          productVariant.variant_name.toLowerCase().includes(productFilter[product?.id]?.value.toLowerCase()) ||
                                          // productVariant.variant_code.includes(productFilter[product?.id]?.value) ||
                                          productVariant.product_attributes.some(attribute => attribute.attribute_value.toLowerCase().includes(productFilter[product?.id]?.value.toLowerCase()))
                                        );
                                      })
                                      .map(productVariant => {
                                        return (
                                          <TableRow key={`product-attribute-${productVariant.id}`}>
                                            <TableCell align="left" style={{ minWidth: 100, maxWidth: 150, width: `100%`, paddingRight: 0 }}>
                                              {width < 600 ? (
                                                <div>
                                                  <div style={{ marginBottom: 3 }}>{productVariant.variant_code}</div>
                                                  <div style={{ marginBottom: 5, position: `relative`, left: -5 }}>
                                                    {productVariant.product_attributes.map(attribute => {
                                                      return (
                                                        <Chip
                                                          key={`product-attribute-chip-${productVariant.id}`}
                                                          label={attribute.attribute_value}
                                                          style={{ marginRight: 5, fontSize: 10 }}
                                                          size={`small`}
                                                        />
                                                      );
                                                    })}
                                                  </div>
                                                  {product?.multiple ? (
                                                    <div style={{ fontWeight: `bold`, marginBottom: 5 }}>
                                                      Multiple: {` `}
                                                      <Tooltip title={`You need to purchase this product in multiples of ${product?.multiple_amount}`} placement="top">
                                                        <Chip label={`${product?.multiple_amount}`} style={{ marginRight: 5, fontSize: 10 }} size={`small`} />
                                                      </Tooltip>
                                                    </div>
                                                  ) : (
                                                    ``
                                                  )}

                                                  <div style={{ fontWeight: `bold`, marginBottom: 5 }}>
                                                    Stock:{' '}
                                                    {productVariant && productVariant?.quantity >= 1 ? (
                                                      <span style={{ color: `green` }}>{productVariant?.quantity}</span>
                                                    ) : (
                                                      <span style={{ color: `red` }}>Not In Stock</span>
                                                    )}
                                                  </div>
                                                </div>
                                              ) : (
                                                productVariant.variant_code
                                              )}
                                              {}
                                            </TableCell>
                                            <TableCell align="left" style={{ minWidth: 150, maxWidth: 200, width: '100%', display: width < 600 ? 'none' : 'table-cell' }}>
                                              {productVariant.product_attributes.map(attribute => {
                                                return (
                                                  <Chip key={`product-attribute-value-chip-${attribute.attribute_value}`} label={attribute.attribute_value} style={{ marginRight: 5 }} size={`small`} />
                                                );
                                              })}
                                            </TableCell>
                                            <TableCell align="center" style={{ width: '100%', minWidth: 30, maxWidth: 50, paddingLeft: 0, paddingRight: 0 }}>
                                              {category?.price_override?.active === 1 ? (
                                                <span>
                                                  <span style={{ color: `red` }}>
                                                    <s>{productVariant.unit_price}</s>
                                                  </span>
                                                  <span> ${product?.lowest_price?.lowest_total}</span>
                                                </span>
                                              ) : product?.customer_saved_pricing && product?.customer_saved_pricing?.item_total !== product.unit_price ? (
                                                <span>
                                                  <span style={{ color: `red` }}>
                                                    <s>{productVariant.unit_price}</s>
                                                  </span>
                                                  <span> ${product?.customer_saved_pricing?.item_total}</span>
                                                </span>
                                              ) : (
                                                <span>${productVariant.unit_price}</span>
                                              )}
                                            </TableCell>
                                            {/*{productVariant?.last_saved_price && productVariant?.last_saved_price?.item_total !== productVariant.unit_price ? (*/}
                                            {/*  <TableCell align="left" style={{ width: 50, maxWidth: 50 }}>*/}
                                            {/*    <Chip*/}
                                            {/*      color={`secondary`}*/}
                                            {/*      // variant={`outlined`}*/}
                                            {/*      label={`${Math.round((1 - productVariant?.last_saved_price?.item_total / productVariant.unit_price) * 100)}% DISCOUNT`}*/}
                                            {/*      // color="success"*/}
                                            {/*    />*/}
                                            {/*  </TableCell>*/}
                                            {/*) : (*/}
                                            {/*  <TableCell align="left" style={{ width: '100%', maxWidth: 50, display: width < 600 ? 'none' : 'table-cell' }} />*/}
                                            {/*)}*/}
                                            {/*<TableCell align="left">{productVariant?.last_saved_price ? productVariant?.last_saved_price?.item_total : productVariant.unit_price}</TableCell>*/}
                                            {product?.multiple ? (
                                              <TableCell align="center" style={{ width: '100%', minWidth: 50, maxWidth: 150, display: width < 600 ? 'none' : 'table-cell' }}>
                                                {product.multiple ? (
                                                  <Tooltip title={`You need to purchase this product in multiples of ${product?.multiple_amount}`} placement="top">
                                                    <Chip label={`${product?.multiple_amount}`} size={`small`} />
                                                  </Tooltip>
                                                ) : (
                                                  ''
                                                )}
                                              </TableCell>
                                            ) : (
                                              ''
                                            )}
                                            <TableCell align="center" style={{ width: '100%', minWidth: 50, maxWidth: 150 }}>
                                              <TextField
                                                size={`small`}
                                                className={`center-input`}
                                                style={{ textAlign: `center` }}
                                                inputProps={{ style: { width: width < 600 ? (width < 400 ? `50px` : `75px`) : `100px` } }}
                                                disabled={
                                                  productVariant?.quantity <= 0 || productVariant !== internalProductVariant
                                                    ? productVariant?.quantity === 0 || cartItemLoading || user?.role?.name === 'sales'
                                                    : false
                                                }
                                                // disabled={
                                                //   productVariant?.quantity <= 0 || productVariant !== internalProductVariant
                                                //     ? productVariant?.quantity === 0 ||
                                                //       cartItemUpdate?.fetching ||
                                                //       cartItemCreate?.fetching ||
                                                //       cart?.fetching ||
                                                //       cartItemLoading ||
                                                //       user?.role?.name === 'sales'
                                                //     : false
                                                // }
                                                defaultValue={
                                                  cart &&
                                                  cart?.data &&
                                                  cart?.data?.cart_items &&
                                                  cart?.data?.cart_items?.length >= 1 &&
                                                  cart?.data?.cart_items.find(item => item.product_id === product.id && item.product_variant_id === productVariant.id)
                                                    ? cart?.data?.cart_items.find(item => item.product_id === product.id && item.product_variant_id === productVariant.id).quantity
                                                    : ``
                                                }
                                                onChange={event => quantityChanged(event, product, productVariant)}
                                                // onBlur={event => quantityChanged(event, product, productVariant)}
                                                variant="outlined"
                                              />
                                            </TableCell>

                                            <TableCell align="center" style={{ width: '100%', minWidth: 80, maxWidth: 100, display: width < 600 ? 'none' : 'table-cell' }}>
                                              <div style={{ fontWeight: `bold` }}>
                                                {productVariant && productVariant?.quantity >= 1 ? (
                                                  <span style={{ color: `green` }}>{productVariant?.quantity}</span>
                                                ) : (
                                                  <span style={{ color: `red` }}>Not In Stock</span>
                                                )}
                                              </div>
                                            </TableCell>
                                          </TableRow>
                                        );
                                      })}
                                </TableBody>
                              </Table>
                            </TableContainer>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  ))
              ) : (
                <div style={{ marginTop: 10 }} key={`empty-cart`}>
                  <Alert severity="warning">There is currently no products for this category</Alert>
                </div>
              )}
            </div>
          ),
        }[view]
      }
    </Grid>
  ) : (
    <Grid item xs={12}>
      <Skeleton />
      <Skeleton animation="wave" />
      <Skeleton animation={false} />
      <Skeleton />
      <Skeleton animation="wave" />
      <Skeleton animation={false} />
      <Skeleton />
      <Skeleton animation="wave" />
      <Skeleton animation={false} />
    </Grid>
  );
};

CategoryViewPage.propTypes = {
  quantityChanged: PropTypes.func,
  user: PropTypes.object,
  cart: PropTypes.object,
  history: PropTypes.object,
  category: PropTypes.object,
  cartItemUpdate: PropTypes.object,
  cartItemCreate: PropTypes.object,
  internalProduct: PropTypes.object,
  internalProductVariant: PropTypes.object,
  width: PropTypes.number,
  cartItemLoading: PropTypes.bool,
};

export default CategoryViewPage;
